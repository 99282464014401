<template>
   <section class="section section--404">
        <b-container>
            <main class="main-content">
                <div class="pagenotfound">
                    <div class="pagenotfound__wrapper">
                        <div class="thumb flex-box just-center">
                            <s-img src="/assets/img/404.png" alt="404"/>
                        </div>
                        <div class="pagenotfound__text">KHÔNG TÌM THẤY TRANG</div>
                        <div class="pagenotfound__subtext">Trang đã bị xóa hoặc địa chỉ url không đúng</div>
                        <n-link class="button" to="/">
                            Trở về trang chủ
                        </n-link>
                    </div>
                </div>
            </main>
        </b-container>
   </section>
</template>

<script>
export default {
    head() {
        return {};
    },
    computed: {
    }
};
</script>
