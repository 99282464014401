<template>
  <component :is="component"/>
</template>

<script>
// eslint-disable-next-line import/no-unresolved
import base from '~/components/base.vue';
// eslint-disable-next-line import/no-unresolved
import desktop from '~/components/desktop/404.vue';

export default {
    extends: base,
    components: {
        desktop
    },
    head() {
        return {};
    },
    computed: {
    }
};
</script>
